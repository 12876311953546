<template>
  <div class="messageList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(messages)"
        :columns="kgm_responsiveColumns()"
        :filterable="false"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(messages)"
        :sortable="{
          allowUnsort: true,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="languageTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span>{{ locales.find(locale => locale.countryCode.toLowerCase() === kgm_getNestedValue(props.field, props.dataItem).toLowerCase()).language }}</span>
          </td>
        </template>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="editMessage(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>{{ $t('edit') }}</span>
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="() => {
                  indexToDelete = props.dataItem.id;
                  removeMessage();
                }"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="trash"
                />
                <span>Remove</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>

      <Sidebar
        :show-sidebar="showSidebar"
        @close="hideSidebar"
      >
        <component
          :is="currentComponent"
          :template-id="templateId"
          :language="currentLanguage"
          :message-id="currentMessage"
          @reloadMessages="reloadMessagesAndCloseSidebar"
        />
      </Sidebar>
      
      <button
        class="btn btn-secondary mt-4"
        @click="$router.push('/smsmanagement/template');"
      >
        <font-awesome-icon
          class="mr-1"
          icon="caret-left"
        />
        <span>
          {{ $t('back') }}
        </span>
      </button>
    </template>

    <SweetModal
      ref="confirmDelete"
      icon="warning"
      blocking
      title="Delete message?"
      class="overflowHidden"
    >
      <p>Are you sure you want to delete this message?</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeMessage()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />
        <span>{{ $t('delete') }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

import AddMessage from '@/components/SmsManagement/AddMessage.vue';
import MessageEdit from '@/components/SmsManagement/MessageEdit.vue';
import Sidebar from '@/components/Base/Sidebar.vue';

import { SweetModal, SweetModalTab } from 'sweet-modal-vue';

export default {
  name: "MessageList",
  components: {
    Sidebar,
    AddMessage,
    MessageEdit,
    SweetModal,
    SweetModalTab
  },
  mixins: [
    kendoGridMixin
  ],
  props: {
    templateId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      messages: null,
      locales: null,
      showSidebar: false,
      currentComponent: null,
      currentLanguage: null,
      currentMessage: null,
      indexToDelete: null,
      kgm_columns: [
        {
          field: 'subject',
          filterable: true,
          filter: 'text',
          title: 'Subject'
        },
        {
          field: 'message',
          filterable: true,
          filter: 'text',
          title: 'Message'
        },
        {
          field: 'language',
          filterable: true,
          filter: 'text',
          title: 'Language',
          width:'150px',
          cell: 'languageTemplate'
        },
        {
          filterable: false,
          title: 'Options',
          width:'160px',
          cell: 'optionsTemplate'
        }
      ]
    }
  },
  created () {
    this.getData();
    this.$eventBus.$on("removeMessage", this.openConfirmRemoveModal);
    this.$eventBus.$on("editMessage", this.editMessage);
  },
  beforeDestroy () {
    this.$eventBus.$off("removeMessage", this.openConfirmRemoveModal);
    this.$eventBus.$off("editMessage", this.editMessage);
  },
  methods: {
    openConfirmRemoveModal (index) {
      this.indexToDelete = index;
      this.$refs.confirmDelete.open();
    },
    //#region Sidebar
    show () {
      this.showSidebar = true;
    },
    hideSidebar () {
      this.showSidebar = false;
      this.currentComponent = null;
    },
    showAddMessage () {
      this.currentComponent = "AddMessage";
      this.currentLanguage = "de";
      this.show();
    },
    editMessage (emitValues) {
      this.currentComponent = "MessageEdit";
      this.currentMessage = emitValues.toString();
      this.show();
    },
    //#endregion
    //#region API-calls
    getData () {
      this.loading = true;
      this.axios.all([
        this.axios.get('/SmsManagement/GetDefaultLanguages'),
        this.axios.get(`/SmsManagement/GetMessagesByTemplateId?templateId=${ this.templateId }`)
      ]).then(
        this.axios.spread((localesresponse, messageResponse) => {
          if (localesresponse == null) {
            return;
          }
          if (localesresponse.data == null) {
            return;
          }
          this.locales = localesresponse.data;
          
          if (messageResponse == null) {
            return;
          }
          if (messageResponse.data == null) {
            return;
          }
          this.messages = messageResponse.data;
          this.$emit('checkLanguages');
        }
      ))
      .finally(() => {
        this.loading = false;
      });
    },
    removeMessage () {
      this.loading = true;
      this.axios.delete(`/SmsManagement/RemoveMessage?messageId=${ this.indexToDelete }`)
        .then(() => {
          this.getData();
          this.$eventBus.$emit("reloadMessages");
          this.$emit("reloadAuditLogs");
        })
        .finally(() => {
          this.indexToDelete = null;
          this.$refs.confirmDelete.close();
          setTimeout(() => {
            this.loading = false;
          }, 1500);
        });
    },
    reloadMessagesAndCloseSidebar () {
      this.hideSidebar();
      this.getData();
      this.$emit("reloadAuditLogs");
    }
    //#endregion
  }
}
</script>
<style>
.messageList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>