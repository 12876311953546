<template>
  <div class="messageEdit">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="message">
        <div class="row">
          <div class="col-12">
            <div class="cell">
              <label>{{ $t('subject') }}</label>
              <textarea
                ref="subjectArea"
                v-model="message.subject"
                v-focus
                :class="['form-control subject-area m-input m-input--air', {'is-invalid': this.$validator.errors.has('Subject') || this.$validator.errors.has('Macro') }]"
                placeholder="Enter a subject to be added"
                type="text"
                rows="2"
              />
              <span
                v-show="errors.has('Subject')"
                class="badge badge-danger"
              >{{ errors.first('Subject') }}</span>
              <span
                v-show="errors.has('Macro')"
                class="badge badge-danger"
              >{{ errors.first('Macro') }}</span>
            </div>
          </div>
          <div class="col-12">
            <label>{{ $t('message') }}</label>
            <textarea
              ref="messageArea"
              v-model="message.message"
              v-focus
              :class="['form-control message-area m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Message') || this.$validator.errors.has('Macro') }]"
              type="text"
              rows="8"
            />
            <span
              v-show="errors.has('Message')"
              class="badge badge-danger"
            >{{ errors.first('Message') }}</span>
            <span
              v-show="errors.has('Macro')"
              class="badge badge-danger"
            >{{ errors.first('Macro') }}</span>
          </div>
          <div class="col-12 mt-3">
            <div class="cell">
              <h5>{{ 'Placeholders' }}</h5>
              <h6>{{ $t('common') }}</h6>
              <div class="row">
                <button
                  class="btn btn-secondary"
                  @click="addPlaceholder('{installation_name}')"
                >
                  {{ $t('installationName') }}
                </button>
                <button
                  class="btn btn-secondary ml-3"
                  @click="addPlaceholder('{current_date_time}')"
                >
                  {{ $t('date') }}
                </button>
              </div>
              <h6 class="mt-4">
                {{ 'Group Customer Notification' }}
              </h6>
              <div class="row">
                <button
                  class="btn btn-secondary"
                  @click="addPlaceholder('{linked_installation_names}')"
                >
                  {{ $t('installationNames') }}
                </button>
                <button
                  class="btn btn-secondary ml-3"
                  @click="addPlaceholder('{first_name}')"
                >
                  {{ 'First Name' }}
                </button>
                <button
                  class="btn btn-secondary ml-3"
                  @click="addPlaceholder('{last_name}')"
                >
                  {{ 'Last Name' }}
                </button>
              </div>
              <h6 class="mt-4">
                {{ 'Automated Customer Notification' }}
              </h6>
              <div class="row">
                <button
                  class="btn btn-secondary"
                  @click="addPlaceholder('{installation_url}')"
                >
                  {{ 'Installation URL' }}
                </button>
                <button
                  class="btn btn-secondary ml-3"
                  @click="addPlaceholder('{host_name}')"
                >
                  {{ 'Hostname' }}
                </button>
                <button
                  class="btn btn-secondary ml-3"
                  @click="addPlaceholder('{trigger_name}')"
                >
                  {{ 'Trigger Name' }}
                </button>
                <button
                  class="btn btn-secondary mt-2 mr-2"
                  @click="addPlaceholder('{trigger_severity}')"
                >
                  {{ 'Trigger Severity' }}
                </button>
                <button
                  class="btn btn-secondary mt-2"
                  @click="addPlaceholder('{service_state}')"
                >
                  {{ 'Service State' }}
                </button>
                <button
                  class="btn btn-secondary mt-2"
                  @click="addPlaceholder('{software_component_url}')"
                >
                  {{ 'Software Component URL' }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-12">
            <br>
            <button
              class="btn btn-primary float-right"
              @click="updateMessage()"
            >
              <font-awesome-icon
                class="mr-1"
                icon="upload"
              />
              <span>
                {{ $t('save') }}
              </span>
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js'

export default {
  name: "MessageEdit",
  mixins: [
    errorMixin
  ],
  props: {
    messageId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      message: null,
      selectedArea: null,
    }
  },
  watch:{
    messageId: function () {
      this.getMessage();
    }
  },
  created () {
    this.getMessage();
    document.addEventListener('focusin', this.focusChanged);
  },
  beforeDestroy () {
    document.removeEventListener('focusin', this.focusChanged)
  },
  methods: {
    focusChanged (event) {
      const el = event.target
      if(el.classList.contains('message-area')) {
        this.selectedArea = 'message';
      }
      else if(el.classList.contains('subject-area')) {
        this.selectedArea = 'subject';
      }
    },
    addPlaceholder (val) {
      if(this.selectedArea == 'message') {
        if (this.message.message == null) {
          this.message.message = "";
        }
        var messageArea = this.$refs.messageArea;
        var messagePosition = messageArea.selectionStart
        var msg = this.message.message;
        this.message.message = msg.substring(0, messagePosition) + val + msg.substring(messagePosition);
        messageArea.focus();
      }
      else if(this.selectedArea == 'subject') {
        if (this.message.subject == null) {
          this.message.subject = "";
        }
        var subjectArea = this.$refs.subjectArea;
        var subjectPosition = subjectArea.selectionStart
        var sbj = this.message.subject;
        this.message.subject = sbj.substring(0, subjectPosition) + val + sbj.substring(subjectPosition);
        subjectArea.focus();
      }
    },
    getMessage () {
      this.loading = true;
      this.axios.get(`/SmsManagement/GetMessage?messageId=${ this.messageId }`)
        .then((response) => {
          this.message = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateMessage () {
      this.loading = true;
      let payload = {
        id: this.messageId,
        message: this.message.message,
        language: this.message.language,
        templateId: this.message.templateId,
        subject: this.message.subject
      };
      this.axios.put(`/SmsManagement/UpdateMessage`, payload)
        .then(() => {
          this.error_clear();
          this.$emit("reloadMessages");
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>
