var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"messageList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.messages),"columns":_vm.kgm_responsiveColumns(),"filterable":false,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.messages),"sortable":{
        allowUnsort: true,
        mode: _vm.kgm_sortMode
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"languageTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('span',[_vm._v(_vm._s(_vm.locales.find(function (locale) { return locale.countryCode.toLowerCase() === _vm.kgm_getNestedValue(props.field, props.dataItem).toLowerCase(); }).language))])])]}},{key:"optionsTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.editMessage(props.dataItem.id)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"edit"}}),_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])],1),_c('button',{staticClass:"btn btn-secondary btn-sm",on:{"click":function () {
                _vm.indexToDelete = props.dataItem.id;
                _vm.removeMessage();
              }}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"trash"}}),_c('span',[_vm._v("Remove")])],1)])])]}}])}),_c('Sidebar',{attrs:{"show-sidebar":_vm.showSidebar},on:{"close":_vm.hideSidebar}},[_c(_vm.currentComponent,{tag:"component",attrs:{"template-id":_vm.templateId,"language":_vm.currentLanguage,"message-id":_vm.currentMessage},on:{"reloadMessages":_vm.reloadMessagesAndCloseSidebar}})],1),_c('button',{staticClass:"btn btn-secondary mt-4",on:{"click":function($event){return _vm.$router.push('/smsmanagement/template');}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"caret-left"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")])],1)],_c('SweetModal',{ref:"confirmDelete",staticClass:"overflowHidden",attrs:{"icon":"warning","blocking":"","title":"Delete message?"}},[_c('p',[_vm._v("Are you sure you want to delete this message?")]),_c('button',{staticClass:"btn btn-secondary float-left mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.$refs.confirmDelete.close()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2 gray",attrs:{"icon":"times"}}),_c('span',[_vm._v(_vm._s(_vm.$t('cancel')))])],1),_c('button',{staticClass:"btn btn-danger float-right mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.removeMessage()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"trash"}}),_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])],1),_c('div',{staticClass:"clearfix"})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }